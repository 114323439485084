import React from "react"
import { Card } from 'react-bootstrap'

const cardStyle = {
  backgroundColor: "transparent",
  minWidth: "100%",
}


export default function Aida4Details(props) {
  return (
    <>
       <Card style={cardStyle}>
        <Card.Body bg-transparent>
        <h3><u>AIDA 4</u></h3>
          <h6>Prerequisites:</h6>
          <p>Be 18 years of age or older
            Have completed the AIDA 3 course or have completed the AIDA 3 Crossover Evaluation if crossing over from another freediving agency
            Have completed a course in First Aid with CPR within the last two years
          </p>
          <h6>Requirements:</h6>
          <p>3:30 minutes STA; 70 meters DYN, 32 meters CWT, theoretical exam</p>
          <p>Maximum : 38m deep</p>
          <p>This course familiarises students with techniques, knowledge and safety procedures for deep freediving that go beyond recreational freediving.</p>
          <p>The new techniques will include FRC diving, mouth-fill equalization and packing.</p>
          <p>The skills are developed within these categories of freediving: Static Apnea, Dynamic Apnea, Constant Weight, Free Immersion and Variable Weight.</p>
          <p>In addition to the in-water skills this course introduces full body warm-ups and stretching, specific stretching of breathing muscles, training concepts and diet that benefits the freediver and help shape their development as a diver.</p>
          <p>You will also be, acquainted with packing and reverse packing, mainly within vital capacity as a part of the dry lessons.</p>
          <p> An important set of skills concerns managing open water equipment. The use of appropriate knots and braiding techniques, setting the depth on the dive line, constructing a safe bottom weight from a weight belt and diving leads, safe handling of other additional equipment (bottom plate, torches, lanyards, etc.).</p>
          <p>One of the purposes of this course is to prepare successful candidates for the role of "Assistant Instructor".</p>
          <p>This new role will include leading an appropriate warm-up session for confined and open water freediving and supervising students of all course levels during their confined or open water sessions.</p>
          <p> The theory sessions will include: Physics - Dalton's Law, Boyle's Law, Henry's law, Decompression Sickness, Nitrogen Narcosis, Failure Depth and how to increase VC and decrease RV, FRC Diving, Mouth-Fill, Packing, Fitness and Training, Nutrition and Diet for Freedivers.</p>
          <p>Package Include : </p>
          <li>Insurance fee</li>
			    <li>Certification fee</li>
			    <li>Administration fee</li>
          <li>Education materials fee</li>
          <br></br>
          <strong>Own Materials Required</strong>
          <p>Price: 470&euro;</p>
         </Card.Body>
      </Card>
      </>
  )
}
