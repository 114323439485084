import React from "react"
import AidaDetails2 from "./aidaDetails2"

const imgStyle = {
	maxHeight: '210px',
	maxWidth: '210px',
	// display: 'block',
	marginLeft: 'auto',
	marginRight: 'auto',
}

const cardStyle = {
	minHeight: '100px'
}

export default function Moto(props) {
	return (
		<>
		<section>
		<h1 className="pt-5">Courses</h1>
		<div className="row">
		<div className="col-lg-12 col-md-12 col-sm-12">
		<section className="shadow mb-5 card bg-transparent border-0">
		<div style ={cardStyle} className="card-body">
		<h2 className="card-title text-light">AIDA courses</h2>
		<p>
		Freediving Tenerife takes only 3 students at the buoy to provide the best personalized service and teaching possible.<br/>
		I also organize specific workshops on request, number of people to be defined, spread on a week, contact me for more details.
		</p>
		<strong>Specials :</strong><br/>
		<strong>Courses in french, english or spanish</strong>
		<br/><br/>
		<li>COACHING SESSION (half day) 60€ : includes dry breathing & equalization exercices, open water session tailored to your needs.<br/> </li>
		<li>MASTER PROGRAM 1 MONTH 650€ : stay on my side 1 month to develop your skills and to see what's the life of a full time instructor. Send me a message for all details.</li> 
		<li>MOUTHFILL WEEK 650€ : 6 days in the water + 5 video calls before to come here + follow-up, contact me for details.</li>
		<li>HALF DAY INTRODUCTION 80€ : includes AIDA theory material and certification, insurance, equipement and open water sesion.</li>
		<li>ONLINE COACHING 1 MONTH 200€ : improve your static/dynamic/depth and equalization with a program tailored to your needs.</li>
		<li>Belt and weight always provided.</li>
		<li>Equipment available 15€ : pack wetsuit, mask snorkel and fins, has te be refunded if lost or replaced by the same model.</li>
		<li>Deposit requested to confirm the reservation, cannot be refunded.</li>
		<p>
		</p>
		<AidaDetails2 />
		</div>
		</section>
		</div>
		</div>
		</section>
		</>
	)
}
